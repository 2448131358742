<!--
/**
 * Icon 组件，使用 iconfont 上的图标集，传入 href 属性
 * 详情咨询设计同学
 */
-->
<template>
  <svg class="icon operaIcon" style="cursor: pointer;" aria-hidden="true" @click.stop="handleClick">
    <use :xlink:href="href" />
  </svg>
</template>

<script>
export default {
  props: {
    href: {
      required: true
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style></style>
