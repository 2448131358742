import { Loading } from 'element-ui'

export function onLoading(param) {
  return Loading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.2)',
    target: document.querySelector(param) //loading需要覆盖的DOM节点
  })
}
