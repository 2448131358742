<template>
  <div class="jy-status-layout">
    <div ref="indicator" class="jy-status-indicator" />
    <div class="jy-status-label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    color: {
      required: true
    }
  },
  mounted() {
    this.$refs.indicator.style.backgroundColor = this.color
  }
}
</script>

<style lang="less" scoped>
@import '../../theme/variables.less';
.jy-status-layout {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jy-status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: @status-normal-color;
}
.jy-status-label {
  margin-left: 5px;
}
</style>
