<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      isRouterAlive: true
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less">
html {
  background-color: #f8fafc;
}
#app {
  text-align: center;
  height: 100%;
}
div ::v-deep .operaIcon {
  width: 16px;
  height: 18px;
}
div ::v-deep .ivu-breadcrumb {
  font-size: 12px !important;
  color: #3a4e64 !important;
  line-height: 28px !important;
}
/*div ::v-deep .ivu-modal .ivu-btn.ivu-btn-primary {*/
/*  background: rgb(90, 119, 214);*/
/*  border: none;*/
/*  &:hover {*/
/*    background: #506ccb;*/
/*  }*/
/*}*/
/*div ::v-deep .ivu-modal-footer .modal-btn:first-child {*/
/*  &:hover {*/
/*    border: 1px solid rgb(90, 119, 214);*/
/*    color: rgb(90, 119, 214);*/
/*  }*/
/*}*/
div /deep/ i {
  font-size: 20px;
}
</style>
