import courseApi from '@api/course'

const state = {
  teacherList: null,
  loading: false
}
const getters = {
  teacherList: state => {
    return state.teacherList
  }
}
const actions = {
  getTeacherList({ commit }) {
    if (state.loading) {
      return
    }
    commit('setLoading', true)
    return courseApi
      .getTeacherList()
      .then(res => {
        commit('setTeacherList', res)
        return res
      })
      .finally(() => {
        commit('setLoading', false)
      })
  }
}
const mutations = {
  setTeacherList(state, res) {
    state.teacherList = res.res
  },
  setLoading(state, loading) {
    state.loading = loading
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
