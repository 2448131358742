import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

const baseUrl = config.baseUrl + '/course/api/v1'
let courseUrl = config.baseUrl + '/course/api/v1/course'
export default {
  //修改目标对应的阶段
  newTargetGradationModify(payload, gradationId) {
    return axios.post(`${baseUrl}/target/modify/target-gradation?gradationId=${gradationId}`, payload)
  },
  //修改目标
  newTargetModify(payload, targetId) {
    return axios.post(`${baseUrl}/target/modify?targetId=${targetId}`, payload)
  },
  //企业端分页搜索分类对应的企业课程信息
  searchForOrganization(payload, pageNum, pageSize) {
    return axios.post(`${courseUrl}/org/search?pageNumber=${pageNum}&pageSize=${pageSize}`, payload)
  },
  //企业端分页搜索分类对应的平台课程信息
  searchForPlatform(payload, pageNum, pageSize) {
    return axios.post(`${courseUrl}/org/search/platform?pageNumber=${pageNum}&pageSize=${pageSize}&keyword=${payload}`)
  },
  //获取课程名称信息接口
  getCourseName() {
    return axios.get(`${courseUrl}/get/course/name`)
  },
  //未整理接口
  getTargets() {
    return axios.get(baseUrl + '/target/canvas/get/targets')
  },
  saveTargets(data) {
    return axios.post(baseUrl + '/target/canvas/add/targets', data)
  },
  getPractisesByChapterId(chapterId) {
    return axios.get(`${baseUrl}/practice/chapter/practices?chapterId=${chapterId}`)
  },
  deletePractisesByChapterId(chapterId, practiceIds) {
    return axios.post(`${baseUrl}/practice/delete/chapter/practices?chapterId=${chapterId}`, practiceIds)
  },
  getCourseListByPost(data) {
    return axios.get(`${baseUrl}/course/list/byPost?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&postId=${data.postId}`)
  },
  getCourseList(data) {
    return axios.get(`${baseUrl}/course/list?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`)
  },
  getCourseDetail(courseId) {
    return axios.get(`${baseUrl}/course/details?courseId=${courseId}`)
  },
  addChapter(data) {
    return axios.post(`${baseUrl}/chapter/add`, data)
  },
  updateChapter(data) {
    return axios.post(`${baseUrl}/chapter/update`, data)
  },
  deleteChapter(chapterId) {
    return axios.post(`${baseUrl}/chapter/delete?chapterId=${chapterId}`)
  },
  getAllCourses() {
    return axios.get(`${baseUrl}/course/category/list`)
  },
  addCourses(data) {
    return axios.post(`${baseUrl}/course/add`, data)
  },
  getTeacherList() {
    return axios.get(`${baseUrl}/teacher/list`)
  },
  addTeacher(data) {
    return axios.post(`${baseUrl}/teacher`, data)
  },
  addLiveSection(data) {
    return axios.post(`${baseUrl}/section/live/add`, data)
  },
  updateLiveSection(data) {
    return axios.post(`${baseUrl}/section/live/update`, data)
  },
  addVideoSection(data) {
    return axios.post(`${baseUrl}/section/video/add`, data)
  },
  updateVideoSection(data) {
    return axios.post(`${baseUrl}/section/video/update`, data)
  },
  getLatestLiveList(courseId) {
    return axios.get(`${baseUrl}/section/live/list/latest/${courseId}`)
  },
  addDatumSection(data) {
    return axios.post(`${baseUrl}/section/datum/add`, data)
  },
  updateDatumSection(data) {
    return axios.post(`${baseUrl}/section/datum/update`, data)
  },
  deleteSection(data) {
    return axios.post(`${baseUrl}/section/delete`, data)
  },
  getSectionsOfChapter(chapterId) {
    return axios.get(`${baseUrl}/chapter/details/${chapterId}`)
  },
  getCourseMembers(courseId, pageNumber, pageSize) {
    return axios.get(`${baseUrl}/course/member/list/${courseId}?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  },
  getCourseMemberIds(courseId) {
    return axios.get(`${baseUrl}/course/member/ids/${courseId}`)
  },
  addCourseMembers(data) {
    return axios.post(`${baseUrl}/course/member/add`, data)
  },
  deleteCourseMembers(data) {
    return axios.post(`${baseUrl}/course/member/delete`, data)
  },
  updateCoursePublicity(data) {
    return axios.post(`${baseUrl}/course/publicity/update`, data)
  },
  getCourseDatumsList(id) {
    return axios.get(`${baseUrl}/course/datums/${id}`)
  },
  delCourses(ids) {
    return axios.post(`${baseUrl}/course/batch/delete`, ids)
  },
  //用户删除岗位课程
  deletePostCourses(data) {
    return axios.post(`${baseUrl}/course/delete/post-courses?courseIds=${data.courseIds}&postId=${data.postId}`)
  },
  searchByKeyword(data) {
    return axios.post(`${baseUrl}/course/list/manager/filter`, data)
  },
  advanceSearch(data) {
    return axios.post(`${baseUrl}/course/list/manager/filter/advance`, data)
  },
  editCourse(data) {
    return axios.post(`${baseUrl}/course/update`, data)
  },
  getManagerList(courseId) {
    return axios.get(`${baseUrl}/course/manager/list?courseId=${courseId}`)
  },
  changeManager(data) {
    return axios.post(`${baseUrl}/course/manager/allocation`, data)
  },
  addChapterPractice(data) {
    return axios.post(`${baseUrl}/practice/add/chapter/practice`, data)
  },
  addPractice(data) {
    return axios.post(`${baseUrl}/practice/add`, data)
  },
  closePractice(practiceId) {
    return axios.post(`${baseUrl}/practice/close/${practiceId}`)
  },
  deletePractice(practiceId) {
    return axios.post(`${baseUrl}/practice/delete/${practiceId}`)
  },
  getPracticeDetail(practiceId) {
    return axios.get(`${baseUrl}/practice/details/${practiceId}`)
  },
  getCoursePracticeList(courseId) {
    return axios.get(`${baseUrl}/practice/list/${courseId}`)
  },
  releasePractice(practiceId) {
    return axios.post(`${baseUrl}/practice/release/${practiceId}`)
  },
  updatePractice(data) {
    return axios.post(`${baseUrl}/practice/update`, data)
  },
  createExam(data) {
    return axios.post(`${baseUrl}/exam/create`, data)
  },
  getCourseExams(courseId, pageNumber = 1, pageSize = 5) {
    return axios.get(`${baseUrl}/exam/info/${courseId}?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  },
  getCourseNewExams(courseId) {
    return axios.get(`${baseUrl}/exam/course/exams?courseId=${courseId}`)
  },
  deleteCouresExam(examId) {
    return axios.post(`${baseUrl}/exam/delete/${examId}`)
  },
  deleteCouresNewExam(courseId, examId) {
    return axios.post(`${baseUrl}/exam/course/exam/delete?courseId=${courseId}&examId=${examId}`)
  },
  addNewExam(courseId, examId) {
    return axios.post(`${baseUrl}/exam/course/exam/add?courseId=${courseId}&examId=${examId}`)
  },
  getAvailableStaffsByDepartments(departmentIds, courseId) {
    return axios.get(`${baseUrl}/course/member/staffs/batch?courseId=${courseId}&${departmentIds.map(id => `departmentIds=${id}`).join('&')}`)
  },
  getMyCourse(payload) {
    return axios.get(`${baseUrl}/course/list/own?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`)
  },
  /**
   * 获取组织下所有课程数的统计
   * @returns {AxiosPromise<any>}
   */
  getCourseCountStatistic() {
    return axios.get(`${baseUrl}/statistic/course/sum`)
  },

  /**
   * 获取最受欢迎的前十门课和各自的访问量
   */
  getPopularTop10() {
    return axios.get(`${baseUrl}/statistic/course/popular`)
  },
  /**
   * 获取课程统计列表数据
   * @returns {AxiosPromise<any>}
   */
  getCourseStatisticList(pageNumber = 0, pageSize = 10) {
    return axios.get(`${baseUrl}/statistic/course?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  },
  /**
   * 获取 学习数据 列表
   * @param pageNumber
   * @param pageSize
   * @returns {AxiosPromise<any>}
   */
  getStudyStatisticList(pageNumber = 0, pageSize = 10) {
    return axios.get(`${baseUrl}/statistic/course/study/all?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  },
  closeCourse(id) {
    return axios.get(`${baseUrl}/course/close/${id}`)
  },
  openCourse(id) {
    return axios.get(`${baseUrl}/course/open/${id}`)
  },
  getCourseAbility(id) {
    return axios.get(baseUrl + `/course-ability/get?courseId=${id}`)
  },
  addCourseAbility(id, ability) {
    return axios.post(baseUrl + `/course-ability/add?courseId=${id}`, ability)
  },
  delCourseAbility(id, ability) {
    return axios.post(baseUrl + `/course-ability/delete?courseId=${id}`, ability)
  },
  getchapterByCourseId(courseId) {
    return axios.get(`${baseUrl}/course/member/progress/video/details?courseId=${courseId}`)
  },
  getChapterByCourseId(courseId, userId) {
    return axios.get(`${baseUrl}/course/member/admin/progress/video/details/${courseId}?userId=${userId}`)
  },
  getRooter() {
    return axios.get(`${baseUrl}/course/category/root`)
  },
  addCategory(addCategoryDTO) {
    return axios.post(`${baseUrl}/course/category/add`, addCategoryDTO)
  },
  getClassification(pageNumber, pageSize, categoryId) {
    return axios.get(`${baseUrl}/course/category/courses?pageNumber=${pageNumber}&pageSize=${pageSize}&categoryId=${categoryId}`)
  },
  getPostAvailable(id, pageNumber, pageSize, categoryId) {
    return axios.get(
      baseUrl + `/course/category/post/courses/available?postId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&categoryId=${categoryId}`
    )
  },
  searchLesson(keyword) {
    return axios.get(`${baseUrl}/course/list/keyword?keyword=${keyword}`)
  },
  getCategoryTree(categoryId) {
    return axios.get(`${baseUrl}/course/category/tree?categoryId=${categoryId}`)
  },
  getCategoryCoursesAll(categoryId) {
    return axios.get(`${baseUrl}/course/category/courses/all?categoryId=${categoryId}`)
  },
  deleCategory(categoryId) {
    return axios.post(`${baseUrl}/course/category/delete?categoryId=${categoryId}`)
  },
  updateClassInfo(modifyCategoryDTO) {
    return axios.post(`${baseUrl}/course/category/modify`, modifyCategoryDTO)
  },
  searchClass(pageNumber, pageSize, keyword) {
    return axios.get(`${baseUrl}/course/search?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`)
  },
  //获取功能分组
  getAllGroup(groupId) {
    return axios.get(`${baseUrl}/group/all?groupId=${groupId}`)
  },
  //获取功能组具备的功能
  getGroupFacility(groupId) {
    return axios.get(`${baseUrl}/group/facility?groupId=${groupId}`)
  },
  //获取功能组对应的课程信息
  getGroupCourses(pageNumber, pageSize, groupId, keyword) {
    return axios.get(`${baseUrl}/group/search/courses?pageNumber=${pageNumber}&pageSize=${pageSize}&groupId=${groupId}&keyword=${keyword}`)
  },
  //获取功能组对应的考试信息
  getGroupExams(pageNumber, pageSize, groupId, keyword) {
    return axios.get(`${baseUrl}/group/exams?pageNumber=${pageNumber}&pageSize=${pageSize}&groupId=${groupId}&keyword=${keyword}`)
  },
  //删除功能组
  getGroupDelete(groupIds) {
    return axios.get(`${baseUrl}/group/delete?groupIds=${groupIds}`)
  },
  //添加功能分组
  getGroupAdd(groupForm) {
    return axios.post(`${baseUrl}/group/add`, groupForm)
  },
  //批量增加功能组对应的要素
  getGroupElements(groupElementForm) {
    return axios.post(`${baseUrl}/group/add/elements`, groupElementForm)
  },
  //批量删除功能组对应的要素
  delGroupElements(groupElementForm) {
    return axios.post(`${baseUrl}/group/delete/elements`, groupElementForm)
  },
  //移动课程到一个分类下
  getGroupMove(categoryId, courseIds) {
    return axios.post(`${baseUrl}/course/category/move?categoryId=${categoryId}&courseIds=${courseIds}`)
  },
  //移除课程分类关系
  delCategory(courseIds) {
    return axios.post(`${baseUrl}/course/category/delete/course-category?courseIds=${courseIds}`)
  },
  //获取功能组对应的人员信息
  getGroupMembers(pageNumber, pageSize, groupId, searchGroupUserDTO) {
    return axios.post(`${baseUrl}/group/search/members?pageNumber=${pageNumber}&pageSize=${pageSize}&groupId=${groupId}`, searchGroupUserDTO)
  },
  // 获得企业人员信息(分角色）
  getGroupRole(groupId) {
    return axios.get(`${baseUrl}/group/role/members?groupId=${groupId}`)
  },
  // 获取部门下人员
  getDepartment(data) {
    return axios.post(`${baseUrl}/group/department/members?groupId=${data.groupId}&departmentId=${data.departmentId}`)
  },
  //用户绑定岗位课程
  getPostCourses(data) {
    return axios.post(`${baseUrl}/course/add/post-courses?courseIds=${data.courseIds}&postId=${data.postId}`)
  },
  // 发布课程/取消发布
  categoryPublish(categoryId) {
    return axios.post(`${baseUrl}/course/category/publish?categoryId=${categoryId}`)
  },
  //移动课程到一个岗位下
  movePostLesson(data) {
    return axios.post(`${baseUrl}/course/move/post?postId=${data.postId}&courseIds=${data.courseIds}`)
  },
  //修改功能组
  groupModify(groupId, data) {
    return axios.post(`${baseUrl}/group/modify?groupId=${groupId} `, data)
  },
  //获取企业全部目标
  getTarget() {
    return axios.get(`${baseUrl}/target/all`)
  },
  //添加目标
  targetForm(data) {
    return axios.post(`${baseUrl}/target/add`, data)
  },
  //修改目标
  targetModify(data) {
    return axios.post(`${baseUrl}/target/modify?targetId=${data.targetId}`, data)
  },
  // 获取目标对应的阶段
  targetGradation(targetId) {
    return axios.get(`${baseUrl}/target/get/target-gradation?targetId=${targetId}`)
  },
  // 获取阶段对应的知识点列表
  getGradationKnowledge(gradationId) {
    return axios.get(`${baseUrl}/target/get/gradation-knowledge?gradationId=${gradationId}`)
  },
  //  删除阶段对应的知识点
  deleKnowledge(data) {
    return axios.post(`${baseUrl}/target/delete/gradation-knowledge`, data)
  },
  //  添加目标对应的阶段
  addGradation(data) {
    return axios.post(`${baseUrl}/target/add/gradation`, data)
  },
  // 添加阶段对应的知识点
  addGradationKnowledge(data) {
    return axios.post(`${baseUrl}/target/add/gradation-knowledge`, data)
  },
  // 批量删除目标
  targetBatchDelete(data) {
    return axios.post(`${baseUrl}/target/batch/delete`, data)
  },
  // 批量删除目标对应的阶段
  delTargetGradation(data, targetId) {
    return axios.post(`${baseUrl}/target/batch/delete/target-gradation?targetId=${targetId}`, data)
  },
  // 清除阶段对应的知识点
  clearGradationKnowledge(gradationId) {
    return axios.post(`${baseUrl}/target/clear/gradation-knowledge?gradationId=${gradationId}`)
  },
  // 拖动阶段至另一个位置
  switchGradation(data) {
    return axios.post(
      `${baseUrl}/target/switch/gradation?sourceGradationId=${data.sourceGradationId}&targetGradationId=${data.targetGradationId}&targetId=${data.targetId}`,
      data
    )
  },
  // 拖动目标至另一个位置
  targetSwitch(data) {
    return axios.post(`${baseUrl}/target/switch?sourceTargetId=${data.sourceTargetId}&objectTargetId=${data.objectTargetId}`, data)
  },
  // 修改目标对应的阶段
  targetModifyTarget(data) {
    return axios.post(`${baseUrl}/target/modify/target-gradation?gradationId=${data.gradationId}`, data)
  },
  // 获取课程对应的目标
  getCourseTarget(courseId) {
    return axios.get(`${baseUrl}/target/get/course-target?courseId=${courseId}`)
  },
  // 添加课程与目标的对应关系
  addCourseTarget(data) {
    return axios.post(`${baseUrl}/target/add/course-target`, data)
  },
  // 清除课程目标
  clearCourseTarget(courseId) {
    return axios.post(`${baseUrl}/target/clear/course-target?courseId=${courseId}`)
  },
  // 删除课程目标
  delCourseTarget(courseId, targetId) {
    return axios.post(`${baseUrl}/target/delete/course-target?courseId=${courseId}&targetId=${targetId}`)
  },
  // 添加章节与阶段的对应关系
  addChapterGradation(data) {
    return axios.post(`${baseUrl}/target/add/chapter-gradation`, data)
  },
  // 获取课程的全部阶段
  getGradationAll(data) {
    return axios.post(`${baseUrl}/target/get/chapter-gradation?chapterId=${data}`)
  },
  // 删除章节对应的阶段
  delChapterGradation(chapterId, gradationId) {
    return axios.post(`${baseUrl}/target/delete/chapter-gradation?chapterId=${chapterId}&gradationId=${gradationId}`)
  },
  clearChapterGradation(chapterId) {
    return axios.post(`${baseUrl}/target/clear/chapter-gradation?chapterId=${chapterId}`)
  },
  //GET /api/v1/target/target/relations查询目标关系
  getTargetRelations() {
    return axios.get(`${baseUrl}/target/target/relations`)
  },
  // POST /api/v1/target/target/relation/add添加目标之间的路径关系
  addTargetRelation(fromTargetId, toTargetId) {
    return axios.post(`${baseUrl}/target/target/relation/add?fromTargetId=${fromTargetId}&toTargetId=${toTargetId}`)
  },
  // POST /api/v1/target/target/relation/delete删除目标之间的路径关系
  deleteTargetRelation(fromTargetId, toTargetId) {
    return axios.post(`${baseUrl}/target/target/relation/delete?fromTargetId=${fromTargetId}&toTargetId=${toTargetId}`)
  },
  // 分页模糊搜索岗位下课程信息列表
  searchPostCourse(data) {
    return axios.get(
      `${baseUrl}/course/search/post/course?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&postId=${data.postId}&keyword=${data.keyword}`
    )
  },
  // 获取资料小节
  getChapterFile(sectionId) {
    return axios.get(`${baseUrl}/section/datum/details/${sectionId}`)
  },
  getEvaList(courseId, pageNum, pageSize) {
    return axios.get(`${baseUrl}/course-evaluation/get?courseId=${courseId}&pageSize=${pageSize}&pageNum=${pageNum}`)
  },
  delEvaById(id) {
    return axios.get(`${baseUrl}/course-evaluation/delete?evaluationId=${id}`)
  }
}
