import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

const baseUrl = config.baseUrl + '/paper/api/v1'
let paperUrl = config.baseUrl + '/paper/api/v1/paper'
let paper2Url = config.baseUrl + '/paper/api/v2/paper'

export default {
  //随机岗位模型试卷规则
  createPostRandom(payload) {
    //方便回滚
    return axios.post(`${paperUrl}/create/post/random`, payload)
    // return axios.post(`${paperUrl}/create/post/random/new`, payload)
  },
  //获取本企业的试卷库列表
  getBank(payload) {
    return axios.get(`${paper2Url}/get/bank${payload ? `?searchContent=${payload}` : ''}`)
  },
  //保存试卷为模板
  saveTemplate(payload) {
    return axios.post(`${paper2Url}/save/template`, payload)
  },
  //保存试卷
  savePaper(payload) {
    return axios.post(`${paperUrl}/save`, payload)
  },
  //更新试卷
  updatePaper(payload) {
    return axios.post(`${paperUrl}/update`, payload)
  },
  //创建试卷
  createNewPaper(examId, payload) {
    return axios.post(`${paper2Url}/create/in-default-bank?examId=${examId}`, payload)
  },
  //获取试卷简要信息
  getPaperBrief(paperId) {
    return axios.get(`${paperUrl}/get/paper/brief?paperId=${paperId}`)
  },
  //复制试卷
  copyPaper(data) {
    return axios.post(`${baseUrl}/paper/copy`, data)
  },
  //根据试卷库Id和条件搜索试卷
  searchAllPaper(pageNumber, pageSize, data) {
    return axios.post(`${paper2Url}/search/outer?pageNumber=${pageNumber}&pageSize=${pageSize}`, data)
  },
  //查询试卷所有详细信息
  getAllPaperInfoById(paperId) {
    return axios.get(`${baseUrl}/paper/all/${paperId}`)
  },
  addRule(data) {
    return axios.post(baseUrl + '/auto/paper/rule/add', data)
  },
  getRule(id) {
    return axios.get(baseUrl + `/auto/paper/rule/get?postId=${id}`)
  },
  addPaperBank(paperBank) {
    return axios.post(`${baseUrl}/bank/create`, paperBank)
  },
  getMyPaperBanks(searchQuestionBankDTO) {
    // 新试卷库搜索接口
    // return axios.get(
    //   `${baseUrl}/bank/list?pageNumber=${pageNumber}&pageSize=${pageSize}&searchContent=${searchContent}`
    // )
    return axios.post(`${baseUrl}/bank/list/search`, searchQuestionBankDTO)
  },
  getAllPaperBanks() {
    return axios.get(`${baseUrl}/bank/all/paperBanks`)
  },
  modifyPaperBank(paperBank) {
    return axios.post(`${baseUrl}/bank/modify`, paperBank)
  },
  deletePaperBank(id) {
    return axios.delete(`${baseUrl}/bank/delete/${id}`)
  },
  /**
   * 根据 id 查询该试卷库下所有的试卷列表
   * @param {*} bankId 试卷库 id
   */
  getPaperList(bankId, pageNumber = 0, pageSize = 10) {
    return axios.get(`${baseUrl}/paper/list/${bankId}?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  },
  getAllPaperList(bankId, page, size) {
    return axios.get(`${baseUrl}/paper/list/${bankId}?pageNumber=${page}&pageSize=${size}`)
  },
  /**
   * 查询试卷所有详细信息
   * @param paperId
   * @returns {AxiosPromise<any>}
   */
  deletePaperByPaperId(paperId) {
    return axios.delete(`${baseUrl}/paper/disable/${paperId}`)
  },
  delPapers(ids) {
    return axios.post(`${baseUrl}/paper/delete`, ids)
  },
  // 新的试卷删除接口
  newPaperByIds(ids) {
    return axios.post(`${baseUrl}/paper/delete/ids`, ids)
  },
  createPaper(form) {
    return axios.post(`${baseUrl}/paper/create`, form)
  },
  searchPaper(pageNumber, pageSize, data) {
    return axios.post(`${baseUrl}/paper/search?pageNumber=${pageNumber}&pageSize=${pageSize}`, data)
  },
  /**
   * 根据参数智能生成一份试卷（但不保存）
   * @param payload
   * @returns {AxiosPromise<any>}
   */
  generatePaperIntelligently(payload) {
    return axios.post(`${baseUrl}/paper/intelligent/preview`, payload)
  },

  /**
   * 获取已使用试卷 id 为 paperId 的试卷的考试
   * @param paperId
   */
  getPaperRelativeExams(paperId) {
    return axios.get(`${baseUrl}/paper/exams?paperId=${paperId}`)
  },

  /**
   * 获取试卷总数统计数据
   * @returns {AxiosPromise<any>}
   */
  getPaperCountStatistic() {
    return axios.get(`${baseUrl}/statistic/paper/sum`)
  },
  // 随机出卷
  randExam(payload) {
    return axios.post(`${baseUrl}/paper/create/random`, payload)
  },
  // 随机出卷规则
  randExamRule(paperId) {
    return axios.get(`${baseUrl}/paper/get/random/rule?paperId=${paperId}`)
  },
  // 随机出卷题目
  randExanQuestion(paperId) {
    return axios.post(`${baseUrl}/public/get/random/paper?paperId=${paperId}`)
  },
  // 添加试卷库分类
  addBankCategory(data) {
    return axios.post(`${baseUrl}/paper/bank/category/add`, data)
  },
  // 删除试卷库分类
  deleteCategoryBank(categoryId) {
    return axios.post(`${baseUrl}/paper/bank/category/delete?categoryId=${categoryId}`)
  },
  // 移除试卷库分类关系
  deleteCourse(questionBankIds) {
    return axios.post(`${baseUrl}/paper/bank/category/delete/course-category?questionBankIds=${questionBankIds}`)
  },
  // 移动试卷库到一个分类下
  moveBankCategory(data) {
    return axios.post(`${baseUrl}/paper/bank/category/move?categoryId=${data.categoryId}&paperBankIds=${data.questionBankIds}`)
  },
  // 获取默认题库的id
  defaultBankId() {
    return axios.get(`${paper2Url}/get/default-bank`)
  },
  // 随机出卷新接口
  randomPaper(payload) {
    return axios.post(`${paper2Url}/create/random`, payload)
  },
  // 获取随机试卷的信息
  randomPaperInfo(paperId) {
    return axios.get(`${paperUrl}/get/random/rule?paperId=${paperId}`)
  },
  // paperId获取题目
  publicPaperInfo(paperId) {
    return axios.get(`${baseUrl}/public/detail?paperId=${paperId}`)
  },
  //随机岗位出卷生成试卷并获取生成试卷ID
  getRandomPostPaperId(paperId) {
    return axios.post(`${baseUrl}/public/get/random/post/paper/id?paperId=${paperId}`)
  },
  //获取随机岗位模型出卷生成规则
  getPostRandom(paperId) {
    return axios.get(`${paperUrl}/get/post/random?paperId=${paperId}`)
  }
}
