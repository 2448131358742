import config from '../config/config'
import { axios } from './index'

const noticeUrl = config.baseUrl + '/user/api/v1/notice'

export default {
  getAllMsgs(page, size) {
    // 获取所有消息
    return axios.get(`${noticeUrl}/get/notices?page=${page}&size=${size}`)
  },
  setUnReadMsg(ids) {
    return axios.post(`${noticeUrl}/read`, ids)
  },
  // 是否有未读消息
  hasUnReadMessages() {
    return axios.post(`${noticeUrl}/is/unread`)
  }
}
