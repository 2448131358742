export function cleanWord(input) {
  let stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g
  let output = input.replace(stringStripper, ' ')
  // 2. strip Word generated HTML comments
  let commentSripper = new RegExp('<!--(.*?)-->', 'g')
  output = output.replace(commentSripper, '')
  let tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi')
  // 3. remove tags leave content if any
  output = output.replace(tagStripper, '')
  // 4. Remove everything in between and including tags '<style(.)style(.)>'
  let badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript']

  for (let i = 0; i < badTags.length; i++) {
    tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi')
    output = output.replace(tagStripper, '')
  }
  // 5. remove attributes ' style="..."'
  let badAttributes = ['style', 'start']
  for (let i = 0; i < badAttributes.length; i++) {
    let attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi')
    output = output.replace(attributeStripper, '')
  }
  output = output.replace(/<br \/>/, '')
  return output
}
