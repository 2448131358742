<template>
  <el-menu class="yt-nav" ref="ytMenu" mode="horizontal" menu-trigger="hover" :collapse="!isSpread">
    <template v-if="navs.length > 0">
      <el-submenu
        v-for="(nav, index) in navs"
        :key="index"
        :index="nav.id.toString()"
        :ref="nav.id.toString() + index"
        :popper-class="nav.popperClass"
        :popper-append-to-body="false"
        :class="{ 'is-opened': $route.path.includes(nav.url) }"
        @click.native="toRoot(nav)"
      >
        <template slot="title">
          <div class="submenu-text">
            <YTIcon :href="`#${nav.icon}`"></YTIcon>
            <span>{{ nav.name }}</span>
          </div>
        </template>
        <template v-if="nav.hasOwnProperty('children')">
          <template v-for="(subNav, subIndex) in nav.children">
            <template v-if="subNav.hasOwnProperty('children') && subNav.children !== null && subNav.children.length > 0">
              <el-submenu :key="subIndex" :index="nav.id.toString() + '-' + subIndex">
                <template slot="title">
                  {{ subNav.name }}
                </template>
                <el-menu-item
                  v-for="(secondaryNav, secondaryIndex) in subNav.children"
                  :key="secondaryIndex"
                  :index="nav.id.toString() + '-' + subIndex + '-' + secondaryIndex"
                  @click="to(secondaryNav.url, nav.id.toString())"
                >
                  {{ secondaryNav.name }}
                </el-menu-item>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item :key="subIndex" :index="nav.id.toString() + '-' + subIndex" @click="to(subNav.url, nav.id.toString())">
                {{ subNav.name }}
              </el-menu-item>
            </template>
          </template>
        </template>
      </el-submenu>
    </template>
  </el-menu>
</template>
<script>
import YTIcon from '@components/common/YTIcon'
export default {
  props: ['isSpread'],
  components: { YTIcon },
  data() {
    return {
      userPermission: '',
      navs: []
    }
  },
  methods: {
    getNavs(navs) {
      this.navs = navs.map(res => {
        if (res.id === 2225) {
          res.children.splice(2, 1)
        } else if (res.id === 2335) {
          res.name = '专业管理'
          res.children[0].name = '专业管理'
          res.children[1].name = '专业匹配'
          res.children[3].name = '专业课程'
        }
        return res
      })
      this.navs.forEach((item, index) => {
        if (item.hasOwnProperty('children') && item.children !== null && item.children.length > 0) {
          let offsetHeight = index * 50 //顶部到元素的距离
          let menuHeight = item.children.length * 53 //菜单高度
          if (offsetHeight + menuHeight > this.$refs.ytMenu.$el.clientHeight) {
            item.popperClass = `yt-submenu down-${item.children.length - 1}`
          } else {
            item.popperClass = 'yt-submenu'
          }
        } else {
          item.popperClass = 'yt-submenu'
        }
      })
    },
    toRoot(nav) {
      if ((nav.hasOwnProperty('children') && nav.children !== null) || this.$route.path === nav.url) {
        return
      }
      this.$router.push(nav.url)
    },
    to(url, key) {
      if (this.$route.path === url) {
        return
      }
      this.$router.push(url)
      this.$refs.ytMenu.open(key)
    }
  }
}
</script>
<style lang="less" scoped>
.nav {
  text-align: left;
  font-size: 14px;
}

.yt-nav {
  height: 100%;
  width: var(--nav-width);
  background: var(--nav-container);
  flex-shrink: 0;
  border-bottom: none !important;

  ::v-deep .el-submenu__title {
    height: 50px !important;
    line-height: 50px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--nav-container);
    color: var(--nav-color) !important;
    border-bottom: none !important;
    padding: 0 26px 0 28px;
    .el-submenu__icon-arrow {
      transform: rotate(-90deg);
    }
  }

  .submenu-text {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
    }
  }

  ::v-deep .el-submenu {
    width: 100%;
    position: relative;

    &:hover {
      position: relative;

      .el-submenu__title {
        color: var(--nav-item-active-color) !important;
        background-color: var(--nav-item-container-active) !important;
      }

      &::before {
        content: '';
        width: 6px;
        height: 100%;
        background-color: var(--nav-item-container-active-left);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }
  }

  ::v-deep .is-opened {
    position: relative;

    .el-submenu__title {
      color: var(--nav-item-active-color) !important;
      background-color: var(--nav-item-container-active) !important;
      .el-submenu__icon-arrow {
        transform: rotate(-90deg);
      }
    }

    &::before {
      content: '';
      width: 6px;
      height: 100%;
      background-color: var(--nav-item-container-active-left);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  ::v-deep .yt-submenu {
    top: -1px !important;
    left: var(--nav-width) !important;

    &.down-1 {
      top: -58px !important;
    }
    &.down-2 {
      top: -111px !important;
    }
    &.down-3 {
      top: -162px !important;
    }
    &.down-4 {
      top: -215px !important;
    }

    .el-menu--popup {
      min-width: 180px;
      padding: 0 34px 0 20px;
      margin-top: 0;
      margin-left: 0;

      .el-menu-item {
        min-width: 126px;
        height: 53px;
        padding-left: 20px;
        line-height: 53px;
        border-bottom: 1px solid #dbdbdb;
        position: relative;
        color: #666666;

        &:hover {
          color: #000;
          font-weight: bold;

          &::before {
            content: '';
            width: 4px;
            height: 14px;
            background-color: var(--nav-item-container-active-left);
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            border-radius: 2px;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .is-active {
        color: #000;
        font-weight: bold;

        &::before {
          content: '';
          width: 4px;
          height: 14px;
          background-color: var(--nav-item-container-active-left);
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          border-radius: 2px;
        }
      }
    }

    .el-submenu {
      border-bottom: 1px solid #dbdbdb;
      padding-left: 10px;
      .el-submenu__title {
        display: flex;
        justify-content: center;
        background-color: #ffffff !important;
        color: #666666 !important;
        font-weight: normal !important;
        .el-submenu__icon-arrow {
          display: none;
        }
        &:hover {
          color: #000 !important;
          font-weight: bold !important;
        }
      }
      &::before {
        width: 4px;
        height: 14px;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
      .el-menu--horizontal {
        top: 0 !important;
        left: 161px !important;
      }
    }
  }
}

.el-menu--collapse {
  width: 54px !important;

  ::v-deep .el-submenu {
    .el-submenu__title {
      padding: 0 20px;
    }
    &:hover {
      &::before {
        width: 0;
      }
    }
  }

  ::v-deep .is-opened {
    &::before {
      width: 0;
    }
  }

  ::v-deep .yt-submenu {
    left: 54px !important;
  }
}
</style>
