<template>
  <div class="manage-container">
    <div class="topbar-container">
      <Topbar :is-spread="isSpread" :organizationName="organizationName" />
    </div>
    <div class="main-container">
      <div class="nav-container" :class="{ in: !isSpread }">
        <Nav :is-spread="isSpread" ref="nav" />
      </div>
      <Tooltip class="spread" placement="right" :class="{ out: !isSpread }" :content="isSpread ? '收起导航' : '展开导航'">
        <div>
          <svg class="icon" @click="toggleSpread" aria-hidden="true">
            <use xlink:href="#icon-open" />
          </svg>
        </div>
      </Tooltip>
      <div class="content-overflow-wrapper" :class="{ in: !isSpread }">
        <div class="content">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Topbar from '@components/Topbar.vue'
import Nav from '@components/Nav.vue'
import sysPermissionApi from '@api/sysPermission'

export default {
  components: {
    Topbar,
    Nav
  },
  mounted() {
    this.getMenu()
  },
  data() {
    return {
      isSpread: true,
      userPermission: '',
      navs: [],
      organizationName: ''
    }
  },
  methods: {
    toggleSpread() {
      this.isSpread = !this.isSpread
    },
    getMenu() {
      sysPermissionApi
        .getMenu()
        .then(res => {
          this.$store.commit('user/setUserInfo', res.res.userInfo)
          sessionStorage.setItem('yt-saas-user-info', JSON.stringify(res.res.userInfo))
          if (sessionStorage.getItem('yt-saas-user-info')) {
            this.organizationName = JSON.parse(sessionStorage.getItem('yt-saas-user-info')).organizationName
          } else {
            this.organizationName = '金融科技智能培训平台'
          }
          if (sessionStorage.getItem('menu-' + res.res.userInfo.phone)) {
            this.navs = JSON.parse(sessionStorage.getItem('menu-' + res.res.userInfo.phone))
          } else {
            this.navs = res.res.menuInfo
            if (this.navs[0].hasOwnProperty('children') && this.navs[0].children !== null && this.navs[0].children.length > 0) {
              this.$router.push(this.navs[0].children[0].url)
            } else {
              this.$router.push(this.navs[0].url)
            }
            sessionStorage.setItem('menu-' + res.res.userInfo.phone, JSON.stringify(res.res.menuInfo))
          }
          this.$refs.nav.getNavs(this.navs)
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.$router.push('/home')
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
@import '../theme/variables';

.manage-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;
  width: 100%;
}

.topbar-container {
  background: transparent;
  //background: var(--top-logo);
  width: 100%;
  height: calc(100vw * var(--top-height) / 1440);
  max-height: calc(var(--top-height) * 1px);
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  z-index: 999;
}

.main-container {
  display: flex;
  width: 100%;
  height: calc(100% - 100vw * var(--top-height) / 1440);
  min-height: calc(100% - var(--top-height) * 1px);
}

.nav-container {
  height: 100%;
  width: var(--nav-width);
  background: var(--nav-container);
  box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
  overflow: visible;
  transition: 0.3s width ease-in-out;
  &::-webkit-scrollbar {
    display: none;
  }
}

.nav-container.in {
  width: 54px;
}

.content {
  height: 100%;
  min-width: calc(100vw - var(--nav-width));
  background-color: var(--content);
  overflow-y: auto;
}
.content-overflow-wrapper {
  width: calc(100% - var(--nav-width));
  height: 100%;
  transition: 0.3s width ease-in-out;
  &.in {
    width: calc(100% - 54px);
  }
}

.spread {
  position: absolute;
  color: white;
  left: calc(var(--nav-width) - 1rem);
  top: 50%;
  z-index: 999;
  cursor: pointer;
  transition: all @default-transition;

  svg {
    transform: rotate(90deg);
    width: 14px;
    height: 14px;
  }

  &.out {
    /*left: calc(50px - 1rem);*/
    top: auto;
    left: 20px;
    bottom: 20px;
    height: 18px;
    svg {
      transform: rotate(270deg);
    }
  }
}
</style>
