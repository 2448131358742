import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

const baseUrl = config.baseUrl + '/knowledge/api/v1'

export default {
  //添加根知识点
  nodeAdd(payload) {
    return axios.post(`${baseUrl}/knowledge/root/node`, payload)
  },
  //验证导入知识点图谱
  uploadVerify(file) {
    return axios.post(`${baseUrl}/knowledge/verify`, file)
  },
  //获取技能知识点
  getAbilityKnowledge(id) {
    return axios.get(baseUrl + `/knowledge/get/ability/knowledge-tree?abilityId=${id}`)
  },
  // 移动知识点
  moveKnowledge(payload) {
    return axios.get(baseUrl + `/knowledge/move?knowledgeId=${payload.knowledgeId}&parentId=${payload.parentId}`)
  },
  getFullKnowledgeTree() {
    return axios.get(baseUrl + '/knowledge/tree')
  },
  getKnowledgeRoot() {
    return axios.get(`${baseUrl}/knowledge/root-nodes/or-id`)
  },
  getRootKnowledgeList() {
    return axios.get(`${baseUrl}/knowledge/root-nodes?status=false`)
  },
  getKnowledgeChildren(id) {
    return axios.get(`${baseUrl}/knowledge/root-nodes/${id}`)
  },
  deleteKnowledge(id) {
    return axios.delete(`${baseUrl}/knowledge/${id}`)
  },
  addRootKnowledge(data) {
    return axios.post(`${baseUrl}/knowledge/root-node`, data)
  },
  addSubKnowledge(parentId, data) {
    // return axios.post(`${baseUrl}/${parentId}/addSub`, data)
    return axios.post(`${baseUrl}/knowledge/child-node`, data)
  },
  searchKnowledge(dto) {
    // 获取知识点接口&字段名更换
    // return axios.get(
    //   `${baseUrl}/knowledge/search?keyword=${keyword}&status=${status}`
    // )
    return axios.post(`${baseUrl}/knowledge/search/info`, dto)
  },
  updateKnowledge(data) {
    return axios.put(`${baseUrl}/knowledge/node`, data)
  },
  //  下载知识点模板
  downloadKnowledge() {
    return axios.post(`${baseUrl}/knowledge/download`, null, {
      responseType: 'blob'
    })
  },
  // 导入模板
  uploadExcel(file) {
    return axios.post(`${baseUrl}/knowledge/upload/excel`, file)
  }
}
