import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './api/index'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import VueChatScroll from 'vue-chat-scroll'
import './theme/index.less'
import filters from './util/filterUtils'
import VueClipboard from 'vue-clipboard2'
import ECharts from 'vue-echarts'
import Element from 'element-ui'
import { Cascader, Tree, Button, Dropdown } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import 'videojs-flash'
import moment from 'moment'
import { cleanWord } from '@util/cleanWord'
import './assets/icon/iconfont.css'
import './assets/icon/iconfont'
import Print from 'vue-print-nb'
const gojs = require('gojs')
import * as echarts from 'echarts' //echarts
import Highlight from './util/hightlight' // 这里是你项目highlight.js所在路径
Vue.use(Highlight)
Vue.prototype.$echarts = echarts
moment.locale('zh-cn')
Vue.use(Print)
Vue.use(VideoPlayer)
Vue.use(Cascader)
Vue.use(Tree)
Vue.use(Button)
Vue.use(Dropdown)
Vue.use(Element)
Vue.component('v-chart', ECharts)
Vue.use(ViewUI)
Vue.use(VueChatScroll)
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.cleanWord = cleanWord
Vue.prototype.go = gojs
Vue.prototype.shns = window.g.isShns

//工具类
import { dataUrlToFile, getChoiceIndex, handleParams, roundFloat, deepCopy, replaceHtml, regSpace, floatMultiply } from '@util/util'
import utilApi from '@util/util'
import ytConstant from '@util/constant'
import { onLoading } from '@util/loading'
import filterUtils from '@/util/filterUtils'
import beautifyTime from '@util/beautifyTime'
import { renameFile, download, downloadFlow, generateUUID } from '@util/fileUtil'
Vue.prototype.$dataUrlToFile = dataUrlToFile
Vue.prototype.$getChoiceIndex = getChoiceIndex
Vue.prototype.$handleParams = handleParams
Vue.prototype.$roundFloat = roundFloat
Vue.prototype.$deepCopy = deepCopy
Vue.prototype.ytConstant = ytConstant
Vue.prototype.$onLoading = onLoading
Vue.prototype.$formatTime = filterUtils.formatTime
Vue.prototype.$beautifyTime = beautifyTime.beautifyTime
Vue.prototype.$overSituationMsg = utilApi.overSituationMsg
Vue.prototype.$examFormatTime = utilApi.examFormatTime
Vue.prototype.$relativeTimeFilter = filterUtils.relativeTimeFilter
Vue.prototype.$renameFile = renameFile
Vue.prototype.$download = download
Vue.prototype.$downloadFlow = downloadFlow
Vue.prototype.$generateUUID = generateUUID
Vue.prototype.$replaceHtml = replaceHtml
Vue.prototype.$regSpace = regSpace
Vue.prototype.$floatMultiply = floatMultiply

const requireComponent = require.context(
  // 其组件目录的相对路径
  './components/common',
  // 是否查询其子目录
  false,
  // 匹配基础组件文件名的正则表达式
  /JY[a-zA-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)

  // 获取组件的 PascalCase 命名
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')

  // 全局注册组件
  Vue.component(
    componentName,
    // 如果这个组件选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    componentConfig.default || componentConfig
  )
})

Vue.filter(filters.relativeTimeFilter.name, filters.relativeTimeFilter)
Vue.filter(filters.formatTime.name, filters.formatTime)

Vue.directive('drag', {
  // 指令的定义
  bind: function(el) {
    let odiv = el //获取当前元素
    odiv.onmousedown = e => {
      const moveTarget = document.querySelector('.custom-move-target')
      //算出鼠标相对元素的位置
      let disX = e.clientX - moveTarget.offsetLeft
      let disY = e.clientY - moveTarget.offsetTop
      document.onmousemove = e => {
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX
        let top = e.clientY - disY

        //绑定元素位置到positionX和positionY上面
        // this.positionX = top
        // this.positionY = left

        //移动当前元素
        moveTarget.style.left = left + 'px'
        moveTarget.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
