import config from '../config/config'
import { axios } from './index'

const departmentUrl = config.baseUrl + '/user/api/v1/department'

export default {
  //获取部门信息（平铺）
  getdepartmentAll() {
    return axios.get(`${departmentUrl}/all`)
  },
  //获取组织结构
  getDepartmentsTree() {
    return axios.get(`${departmentUrl}/tree`)
  },
  //获取组织结构
  getPostMatchDepartmentsTree(payload) {
    return axios.get(`${departmentUrl}/tree/post/match?postId=${payload}`)
  },
  //新建部门
  addDepartment(data) {
    return axios.post(`${departmentUrl}/add`, data)
  },
  //编辑部门
  modifyDepartment(data) {
    return axios.post(`${departmentUrl}/modify`, data)
  },
  //删除部门
  deleteDepartment(data) {
    return axios.post(`${departmentUrl}/delete`, data)
  },
  // //获取部门简要信息
  // getDepartmentInfo(payload) {
  //   return axios.get(`${departmentUrl}/department-info?departmentId=${payload}`)
  // },
  //获取部门简要信息
  getDepartmentInfo(payload) {
    return axios.get(`${departmentUrl}/get/department/leaders?departmentId=${payload}`)
  },
  //未整理接口
  getDepartmentStaff(departmentId, page, size) {
    return axios.get(`${departmentUrl}/staffs?status=0&departmentId=${departmentId}&page=${page}&size=${size}`)
  },
  /**
   * 批量获取多个部门的员工
   * @param departmentIds
   * @returns {AxiosPromise<any>}
   */
  getStaffsByDepartments(departmentIds) {
    return axios.get(`${departmentUrl}/staffs/batch?${departmentIds.map(id => `departmentIds=${id}`).join('&')}`)
  },
  // 发布考试指定人员
  designPersonByExam(payload) {
    return axios.get(`${departmentUrl}/organization/staffs?page=${payload.page}&size=${payload.size}`)
  },
  // 导出员工
  exportdepartPeo(initDepartmentId) {
    return axios({
      method: 'get',
      url: `${departmentUrl}/school/export?departmentId=${initDepartmentId}&api_key=school`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      }
    })
  }
}
