<template>
  <div class="number-displayer">
    <div class="header">
      <JYIcon v-if="icon" :href="icon" style="font-size: 16px" />
      <div class="label">{{ label }}</div>
    </div>
    <div class="number">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'JYNumberDisplayer',
  props: {
    value: {
      type: Number,
      default: 0
    },
    label: {
      type: String
    },
    icon: {
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.number-displayer {
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 5px;
  padding: 10px 30px;
}
.number {
  font-size: 48px;
  line-height: 48px;
  font-weight: 900;
  color: #516586;
  text-align: center;
}
.header {
  display: flex;
  align-items: center;
}
.label {
  flex: 1;
  margin-left: 5px;
}
</style>
