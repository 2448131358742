import config from '../config/config'
import { axios } from './index'

const questionUrl = config.baseUrl + '/question/api/v1/questions'
const paperQuestionUrl = config.baseUrl + '/paper/api/v1/question'
export default {
  //修改题目分数
  modifyQuestionPoint(paperId, payload) {
    return axios.post(`${paperQuestionUrl}/modify/point?paperId=${paperId}`, payload)
  },
  //按照条件分页搜索题目
  searchByForm(payload, pageNum, pageSize) {
    return axios.post(`${questionUrl}/search/detail?page=${pageNum}&size=${pageSize}`, payload)
  },
  //获取使用了题目的试卷
  getQuestionPaperInfo(payload) {
    return axios.get(`${questionUrl}/paper/info?questionId=${payload}`)
  },
  //获取题目审核信息
  getQuestionAuditInfo(payload) {
    return axios.get(`${questionUrl}/question/audit/info?questionId=${payload}`)
  },
  //获取带有题目数量知识点树
  getKnownLedgeTree() {
    return axios.get(`${questionUrl}/knowledge/tree/with-count`)
  },
  //通过条件获取带有题目数量知识点树
  getKnownLedgeTreeByCondition(payload) {
    return axios.post(`${questionUrl}/knowledge/tree/with-count/search`, payload)
  },
  //修改题目审核状态
  auditQuestion(questionId, status) {
    return axios.post(`${questionUrl}/check?questionId=${questionId}&status=${status}`)
  },
  //获取代码题默认模板
  getDefaultTemplate() {
    return axios.get(`${questionUrl}/default/template`)
  },
  //移动题目到另一个题库
  moveQuestions(toQuestionBankId, questionIds) {
    return axios.post(`${questionUrl}/move?toQuestionBankId=${toQuestionBankId}`, questionIds)
  },
  //批量删除题目
  deleteQuestions(questionIds) {
    return axios.post(`${questionUrl}/delete`, questionIds)
  },
  //获取题目信息
  getQuestionInfo(id) {
    return axios.get(`${questionUrl}/detail/${id}`)
  },
  //题目变更标签
  updateTagsForQuestion(questionIds, tagIds) {
    return axios.post(`${questionUrl}/questions-tags/add?questionIds=${questionIds}&tagIds=${tagIds}`)
  },
  //题目变更知识点
  updateKnowledgePointsForQuestion(questionIds, knowledgeIds) {
    return axios.post(`${questionUrl}/questions-knowledgePoints/add?questionIds=${questionIds}&knowledgeIds=${knowledgeIds}`)
  },
  //根据知识点，获取各种题型的题目数量
  getQuestionTypeCount(payload) {
    return axios.post(`${questionUrl}/get/knowledge/question-type/count`, payload)
  },
  //自动出卷
  autoCreateExam(payload) {
    return axios.post(`${questionUrl}/auto/create/exam`, payload)
  },
  //根据条件查询试卷已选题目
  searchPaperNotSlectedQuestion(page, size, payload) {
    return axios.post(`${questionUrl}/search/paper/not-selected/question?page=${page}&size=${size}`, payload)
  },
  //根据条件分页查询试卷未选题目
  searchPaperSelectedQuestion(payload) {
    return axios.post(`${questionUrl}/search/paper/selected/question`, payload)
  },
  //新根据条件分页查询试卷未选题目
  searchPaperSelectedQuestions(payload) {
    return axios.post(`${questionUrl}/paper/selected/questions`, payload)
  },
  //批量新增题目
  addBatchQuestions(payload, id) {
    return axios.post(`${questionUrl}/add/batch?questionBankId=${id}`, payload)
  },
  //手动新增题目
  addQuestion(payload) {
    return axios.post(`${questionUrl}/add`, payload)
  },
  //编辑题目
  modifyQuestion(id, payload) {
    return axios.post(`${questionUrl}/${id}/modify`, payload)
  },
  //获取岗位下各技能题目数量
  getAbilityQuestionCountByPostId(payload) {
    return axios.post(`${questionUrl}/get/post/ability/question`, payload)
  },
  //算法自动选择生成各题型题目数量
  getAbilityQuestionCountByAbilityId(payload) {
    return axios.post(`${questionUrl}/get/auto/question`, payload)
  }
}
