<template>
  <div class="dropdown-panel-layout">
    <div class="target-wrapper" @click="toggle">
      <slot />
    </div>
    <transition name="bounce">
      <div v-if="showContent" class="content-wrapper">
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DropdownPanel',
  data() {
    return {
      showContent: false
    }
  },
  methods: {
    toggle() {
      this.showContent = !this.showContent
    }
  }
}
</script>

<style scoped>
.dropdown-panel-layout {
  position: relative;
}
.content-wrapper {
  position: absolute;
  right: 0;
  top: 20px;
}
.bounce-enter-active {
  animation: bounce-in 0.2s;
}
.bounce-leave-to {
  animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: perspective(300px) rotateX(5deg) translateY(-50px);
  }
  50% {
    transform: perspective(300px) rotateX(5deg) translateY(-25px);
    opacity: 0.5;
  }
  100% {
    transform: perspective(0);
  }
}
</style>
