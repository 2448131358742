import Vue from 'vue'
import Router from 'vue-router'

const Login = () => import('@components/Login.vue')
import Layout from '@components/Layout.vue'
const Member = () => import('@/components/manage/system/organization/Member.vue')
const Role = () => import('@/components/manage/system/role/Role.vue')
const Admin = () => import('@/components/manage/system/admin/Admin.vue')
const Course = () => import('@/components/manage/course/course/Course.vue')
const Curriculum = () => import('@/components/manage/course/course/Post.vue')
const Functional = () => import('@components/manage/course/course/FunctionalGroup.vue')
const TargetManagement = () => import('@/components/manage/course/course/TargetManagement.vue')
const CourseDetail = () => import('@/components/manage/course/course/detail/CourseDetail.vue')
const Data = () => import('@components/manage/course/data/Data.vue')
const CourseExam = () => import('@components/manage/course/exam/Exam.vue')
const Practice = () => import('@components/manage/course/practice/Practice.vue')
const NewPractice = () => import('@/components/manage/course/practice/NewPractice.vue')
const EditPractice = () => import('@components/manage/course/practice/EditPractice.vue')
const PracticeView = () => import('@components/manage/course/practice/PracticeView.vue')
const NewExam = () => import('@components/manage/course/exam/NewExam.vue')
const ExamResult = () => import('@components/manage/course/exam/result/ExamResult.vue')
const UserExamResult = () => import('@components/manage/course/exam/result/UserExamResult.vue')
const Video = () => import('@components/manage/course/video/Video.vue')
const QuestionBankDetail = () => import('@/components/manage/question/bank/QuestionBankDetail.vue')
const Knowledge = () => import('@/components/manage/question/knowledge/Knowledge.vue')
const Exam = () => import('@/components/manage/exam/exam/Exam.vue')
const Examinee = () => import('@components/manage/exam/exam/Examiner.vue')
const Examiner = () => import('@/components/manage/exam/exam/Examiner.vue')
const ReportCard = () => import('@components/manage/exam/exam/reportPerCard')
const PaperBank = () => import('@/components/manage/exam/paperBank/PaperBank.vue')
const EditPaper = () => import('@components/manage/exam/paper/EditPaper.vue')
const PaperEdit = () => import('@components/manage/exam/paper/PaperEdit.vue')
const PaperList = () => import('@/components/manage/exam/paper/PaperList.vue')
const PreviewPaper = () => import('@components/manage/exam/paper/PreviewPaper.vue')
const Read = () => import('@components/manage/exam/read/Read.vue')
const Help = () => import('@components/Help')
const NewCourse = () => import('@components/manage/course/course/NewCourse.vue')
const Live = () => import('@/components/manage/live/Live')
const PaperQuestionSelect = () => import('./components/manage/exam/paper/PaperQuestionSelect')
const PaperEditor = () => import('./components/manage/exam/paper/type/PaperEditor')
const PaperAuto = () => import('./components/manage/exam/paper/autoPaper')
const preViewExam = () => import('@/components/manage/exam/paper/type/preViewExam')
// import PaperEditor from './components/manage/exam/paper/PaperEditor'
const ExamRemarkAllocateModal = () => import('./components/manage/exam/exam/ExamRemarkAllocateModal')
const RemarkExamList = () => import('./components/manage/exam/exam/RemarkExamList')
const RemarkExamUserList = () => import('./components/manage/exam/exam/RemarkExamUserList')
const DoRemark = () => import('./components/manage/exam/exam/DoRemark')
const IntelligentPaper = () => import('./components/manage/exam/paper/IntelligentPaper')
const PersonalMessage = () => import('./components/manage/personal/PersonalMessage')
const PersonalCourse = () => import('./components/manage/personal/PersonalCourse')
const StatisticOverview = () => import('./components/manage/statistic/StatisticOverview')
const CandidateManage = () => import('./components/manage/candidate/CandidateManage')
const CandidateExam = () => import('./components/manage/candidate/CandidateExam')
const Result = () => import('./components/manage/candidate/Result')
const ResultDetail = () => import('./components/manage/candidate/ResultDetail')
const examEva = () => import('./components/manage/exam/exam/examEva')
const Marking = () => import('./components/manage/candidate/Marking')
const MarkQuestions = () => import('./components/manage/candidate/MarkQuestions')
const UserInfo = () => import('./components/UserInfo')
// const Home = () => import('./components/Home')
const Examination = () => import('./components/manage/exam/Examination')
const PostRes = () => import('./components/manage/exam/PostResult')
const Questionnaire = () => import('./components/manage/course/questionnaire/Questionnaire')
const NewQuestionnaire = () => import('./components/manage/course/questionnaire/NewQuestionnaire')
const Rank = () => import('./components/manage/analysis/Rank')
const Appraiser = () => import('./components/manage/appraise/Appraiser')
const AppraisedPerson = () => import('./components/manage/appraise/AppraisedPerson')
const LearningTask = () => import('./components/manage/learning/task')
const TaskDetail = () => import('./components/manage/learning/type/taskDetail')
const answerDetail = () => import('./components/manage/learning/answerDetail')
const paperAnswerDetail = () => import('./components/manage/exam/paper/paperAnswerDetail')
const Intelligence = () => import('./components/manage/exam/Intelligence') //智能出卷
const RulePaper = () => import('./components/manage/exam/RulePaper') //智能出卷规则下的试卷
const IndividualPapers = () => import('./components/manage/exam/paper/IndividualPapers')
const PaperDesignated = () => import('./components/manage/exam/paper/paperDesignated')
const Assessment = () => import('./components/manage/analysis/Assessment')
const AssessmentStatistics = () => import('./components/manage/analysis/AssessmentStatistics')
const CourseA = () => import('./components/manage/dataAnalysis/Course')
const ExamA = () => import('./components/manage/dataAnalysis/Exam')
const QuestionA = () => import('./components/manage/dataAnalysis/Question')
const ExamQ = () => import('./components/manage/dataAnalysis/ExamQuestions')
const ScoreDistribution = () => import('./components/manage/dataAnalysis/ScoreDistribution')
// const ExamDetail = () => import('./components/manage/exam/exam/ExamDetail')
const TargetChart = () => import('./components/manage/course/target/TargetChart')
const Chart = () => import('./components/manage/course/target/Chart')
const Home = () => import('./components/Home')
const NotFoundComponent = () => import('./components/maintain')
const TestPaper = () => import('@/components/manage/paper/index')
const RevieT = () => import('@/components/manage/paper/revieTopic')
const MyReport = () => import('@/components/manage/exam/exam/report/myReport')
const ExamReport = () => import('@/components/manage/exam/exam/report/examReport')

const QuestionBank = () => import('@/components/manage/question/bank/QuestionBank.vue') //题库列表
const QuestionList = () => import('@/components/manage/question/list/QuestionList') //题目列表
const MonitorList = () => import('@/components/manage/monitor/MonitorList') //测评监控列表
const MonitorDetail = () => import('@/components/manage/monitor/MonitorDetail') //测评监控详情
const CourseList = () => import('@/components/manage/course/list/CourseList') //课程列表
// const ExamList = () => import('@/components/manage/exam/list/ExamList') //新考试列表
const ExamNewResult = () => import('@components/manage/exam/list/ExamResult') // 考试结果页面
const OldExamList = () => import('@/components/manage/exam/exam/Exam') //旧考试列表
const TagList = () => import('@/components/manage/tag/TagList') //标签列表
const RolePermissionList = () => import('@/components/manage/user/role/RolePermissionList') //角色权限管理列表
const StaffList = () => import('@/components/manage/user/staff/StaffList') //组织架构
const DepartmentReportList = () => import('@/components/manage/report/DepartmentReportList') //数据报表
const PortrayalDetail = () => import('@/components/manage/analysis/PortrayalDetail') //人才画像
const PortrayalList = () => import('@/components/manage/analysis/PortrayalList') //人才画像
const PaperAutoGeneration = () => import('@/components/manage/exam/paper/PaperAutoGeneration') //自动出卷
const PaperDetail = () => import('@/components/manage/exam/paper/PaperDetail') //试卷详情
const NewPaper = () => import('@/components/manage/exam/exam/newPaper') // 创建新的考试
const SettingExam = () => import('@/components/manage/exam/exam/newPaper/SettingExam') // 编辑考试信息
const ProductManger = () => import('@/components/manage/commodity/CommodityList') // 商品管理
const ProductAdd = () => import('@/components/manage/commodity/CommodityAdd') // 商品添加
const CommodityDetail = () => import('@/components/manage/commodity/CommodityDetail') // 商品添加
const Post = () => import('@/components/manage/function/Post') //岗位模型 简洁版 弃用
const PostModel = () => import('@/components/manage/function/PostModel') //岗位模型
const PostModelDetail = () => import('@/components/manage/function/PostModelDetail') //岗位模型详情
const Ability = () => import('@/components/manage/function/Ability') //岗位技能
const KnowledgePoint = () => import('@/components/manage/function/Knowledge') //知识点管理
const KnowledgeGraphics = () => import('@/components/manage/function/KnowledgeGraphics') //知识点展示
const PostMatch = () => import('@/components/manage/function/PostMatch') //人岗匹配
const PostMatchDetail = () => import('@/components/manage/function/PostMatchDetail') //人岗匹配展示详情
const OrderList = () => import('@/components/manage/commodity/OrderList') // 订单管理
const orderInfo = () => import('@/components/manage/commodity/OrderInfo') // 订单详情
const NewExamList = () => import('@/components/manage/exam/exam/NewExamList') // 新考试列表
const NewExaminee = () => import('@/components/manage/exam/exam/NewExaminee') // 考试考生
const ConsoleTag = () => import('@/components/manage/console/ConsoleTag') //控制台
const NewExamDetail = () => import('@/components/manage/exam/exam/NewExamDetail') // 新的考试详情页面
Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    { path: '/login', name: 'login', component: Login },
    { path: '/home', name: 'home', component: Home },
    {
      path: '/manage',
      component: Layout,
      children: [
        //控制台
        { path: 'console', name: 'consoleTag', component: ConsoleTag },
        //考试列表
        { path: 'exam/list', name: 'newExamList', component: NewExamList },
        { path: 'exam/editor', name: 'settingExam', component: SettingExam },
        {
          path: 'exam/created/paper',
          name: 'newPaper',
          component: NewPaper
        },
        {
          path: 'exam/result',
          name: 'examNewResult',
          component: ExamNewResult
        },
        // { path: 'exam/detail', name: 'examDetail', component: ExamDetail },
        { path: 'exam/personal/report', name: 'myReport', component: MyReport },
        //考生列表
        { path: 'exam/examinee', name: 'newExaminee', component: NewExaminee },
        //阅卷
        { path: 'exam/check', name: 'checkList', component: TestPaper },
        { path: 'exam/check/detail', name: 'checkDetail', component: RevieT },
        //岗位测试
        { path: 'exam/post', component: Examination },
        {
          path: 'exam/post/detail',
          name: 'postExamDetail',
          component: PostRes
        },
        //智能出卷
        { path: 'exam/auto', component: Intelligence },
        {
          path: 'exam/auto/paper',
          name: 'rulePaperList',
          component: RulePaper
        },

        //考试评价
        { path: 'exam/comment', component: examEva },
        //测评监控
        { path: 'exam/monitor', component: MonitorList },
        {
          path: 'exam/monitor/detail',
          name: 'monitorDetail',
          component: MonitorDetail
        },
        //试卷管理
        { path: 'paper', name: 'paperBank', component: PaperBank },
        { path: 'paper/list/:id', name: 'paperList', component: PaperList },
        { path: 'paper/detail', name: 'paperDetail', component: PaperDetail },
        { path: 'paper/edit', name: 'paperEdit', component: PaperEdit },
        { path: 'paper/random', name: 'paperAuto', component: PaperAuto },
        { path: 'paper/editor', component: PaperEditor },
        { path: 'paper/preview', name: 'paperReview', component: preViewExam },
        {
          path: 'paper/auto',
          name: 'paperAutoGeneration',
          component: PaperAutoGeneration
        },
        {
          path: 'exam/paperDesignated',
          name: 'paperDesignated',
          component: PaperDesignated
        },
        {
          path: 'exam/IndividualPapers',
          name: 'individualPapers',
          component: IndividualPapers
        },
        { path: 'exam/ExamReport', name: 'examReport', component: ExamReport },
        //题库列表
        {
          path: 'question/bank',
          component: QuestionBank,
          meta: { keepAlive: true }
        },
        //题目列表
        {
          path: 'question/list',
          name: 'questionList',
          component: QuestionList
        },
        //题库管理
        {
          path: 'question/bank',
          component: QuestionBank,
          meta: {
            keepAlive: true
          }
        },
        { path: 'question/list', component: QuestionList },
        //岗位模型
        { path: 'post/list', name: 'post', component: PostModel },
        { path: 'post/detail', name: 'postModelDetail', component: PostModelDetail },
        //技能管理
        { path: 'post/ability', component: Ability },
        //岗位课程
        { path: 'post/course', name: 'postCourseList', component: Curriculum },
        //知识图谱
        { path: 'knowledge/edit', component: KnowledgePoint },
        { path: 'knowledge/show', component: KnowledgeGraphics },
        //人岗匹配
        { path: 'post/match/detail', name: 'postMatchDetail', component: PostMatchDetail },
        //人岗匹配展示详情
        { path: 'knowledge', component: KnowledgePoint },
        //学习任务
        {
          path: 'study/task',
          component: LearningTask,
          meta: { keepAlive: true }
        },
        {
          path: 'study/task/detail',
          name: 'taskDetail',
          component: TaskDetail
        },
        //课程管理
        {
          path: 'study/course',
          name: 'courseList',
          component: CourseList,
          meta: { keepAlive: true }
        },
        {
          path: 'study/course/detail',
          name: 'courseDetail',
          component: CourseDetail
        },
        { path: 'study/course/practice', component: NewPractice },
        //课程目标
        { path: 'study/target', component: TargetManagement },
        {
          path: 'study/target/chart',
          name: 'targetChart',
          component: TargetChart
        },
        //组织架构
        { path: 'system/staff', name: 'staffList', component: StaffList },
        //权限管理
        { path: 'system/permission', component: RolePermissionList },
        //标签管理
        { path: 'system/tag', component: TagList },
        //人员分析
        { path: 'analysis/staff', component: Rank, meta: { keepAlive: true } },
        {
          path: 'analysis/staff/detail',
          name: 'staffDetail',
          component: Assessment
        },
        {
          path: 'analysis/staff/statistics',
          name: 'staffStatistics',
          component: AssessmentStatistics
        },
        //课程分析
        {
          path: 'analysis/course',
          component: CourseA,
          meta: { keepAlive: false }
        },
        //考试分析
        { path: 'analysis/exam', component: ExamA },
        {
          path: 'analysis/exam/detail',
          name: 'examAnalysisDetail',
          component: ScoreDistribution
        },
        {
          path: 'analysis/exam/questions',
          name: 'examAnalysisQuestions',
          component: ExamQ
        },
        //题目分析
        { path: 'analysis/question', component: QuestionA },
        //数据报表
        { path: 'analysis/report', component: DepartmentReportList },
        //运营管理
        { path: 'operation/product', component: null },
        { path: 'operation/push', component: null },
        //用户安全
        { path: 'userInfo', name: 'userInfo', component: UserInfo },
        // 新考试详情
        {
          path: 'exam/newExamDetail',
          name: 'newExamDetail',
          component: NewExamDetail
        }
      ]
    },
    { path: '/live', component: Live },
    { path: '/NotFoundComponent', component: NotFoundComponent },
    { path: '/help', component: Help },
    {
      path: '/frame/portrayal',
      name: 'framePortrayal',
      component: PortrayalList
    }
  ]
})
//白名单存储不判断权限的路由
const whiteList = [
  'home',
  'userInfo',
  'login',
  'monitorDetail',
  'taskDetail',
  'framePortrayal',
  'paperAutoGeneration',
  'paperRandomGeneration',
  'checkDetail',
  'testPaperRevieT',
  'paperEdit',
  'portrayalDetail',
  'staffStatistics',
  'settingExam',
  'postExamDetail',
  'paperList',
  'paperAuto',
  'paperReview'
]
router.beforeEach((to, from, next) => {
  //无效路由重新跳转
  if (!to.matched.length) {
    router.replace('/home')
    return
  }
  //根据权限跳转到菜单第一个子页面
  let userInfo = sessionStorage.getItem('yt-saas-user-info')
  if (userInfo) {
    let menu = JSON.parse(sessionStorage.getItem('menu-' + JSON.parse(userInfo).phone))
    if (menu) {
      let result = menu.some(item => {
        return to.path.includes(item.url)
      })
      if (!result && !whiteList.includes(to.name)) {
        router.replace(
          menu[0].hasOwnProperty('children') && menu[0].children !== null && menu[0].children.length > 0 ? menu[0].children[0].url : menu[0].url
        )
        return
      }
    }
  } else if (to.path !== '/home' && to.path !== '/login' && to.path !== '/manage') {
    router.replace('/home')
    return
  }

  //跳转时停止还在挂起的接口
  if (window._axiosPromiseArr === undefined) {
    window._axiosPromiseArr = []
  }
  window._axiosPromiseArr.forEach((item, index) => {
    item.cancel('请求取消')
    delete window._axiosPromiseArr[index]
  })

  next()
})

export default router
