import { questionType } from '@util/questionType'
import PaperAPI from '@/api/paper'
const state = {
  selectedQuestionList: [],
  paperTitle: '',
  editorData: {
    currentIndex: '',
    isClassify: false,
    cancelClassify: false,
    type: 6
  },
  IsUpdateQuestionType: false
}

const getters = {
  getEditorData(state) {
    return state.editorData
  },
  getUpdateByQuestion(state) {
    return state.IsUpdateQuestionType
  },
  questionTypeStatistic(state) {
    const ret = {}
    const pureQuestionList = _flattenSection(state.selectedQuestionList)
    pureQuestionList.forEach(question => {
      if (!ret[question.questionType]) {
        ret[question.questionType] = 0
      }
      ret[question.questionType]++
    })
    const retArr = Object.keys(ret).map(key => {
      return {
        label: questionType[key].label,
        count: ret[key]
      }
    })
    return retArr
  },
  pureQuestionList(state) {
    return _flattenSection(state.selectedQuestionList)
  }
}

const actions = {
  /**
   * 编辑试卷的时候，加载试卷详情
   * @param paperId
   */
  async loadEditPaperDetail({ commit }, paperId) {
    const { res } = await PaperAPI.getAllPaperInfoById(paperId)
    commit('setEditPaper', res)
  }
}

const mutations = {
  setEditorData(state, { cancelClassify, isClassify, currentIndex, type }) {
    state.editorData.isClassify = isClassify
    state.editorData.cancelClassify = cancelClassify
    state.editorData.currentIndex = currentIndex
    state.editorData.type = type
  },
  setUpdateByQuestion(state, update) {
    state.IsUpdateQuestionType = update
  },
  /**
   * 重置状态
   * @param state
   */
  reset(state) {
    state.selectedQuestionList = []
    state.paperTitle = ''
  },
  /**
   * 设置某个 Section 的名称
   * @param state
   * @param sIndex index of section
   * @param name
   */
  setSectionName(state, { sIndex, name }) {
    const newList = [...state.selectedQuestionList]
    if (!newList[sIndex]) return
    newList[sIndex].name = name
    state.selectedQuestionList = newList
  },
  /**
   * 新建试卷设置试卷标题
   * @param state
   * @param title
   */
  setPaperTitle(state, title) {
    state.paperTitle = title
  },
  /**
   * 组卷的时候设置用户选择的题目
   * @param state
   * @param question
   */
  handleSelectQuestion(state, { question, isClassify, currentI }) {
    if (state.selectedQuestionList.length === 0) {
      // Section 列表为空，创建一个默认的大题
      state.selectedQuestionList = [{ name: '默认大题', questionVOS: [] }]
    }
    const pureQuestionList = _flattenSection(state.selectedQuestionList)
    const questionIndex = pureQuestionList.findIndex(item => item.questionId === question.questionId)

    const newList = [...state.selectedQuestionList]
    if (questionIndex === -1) {
      // 该题不存在，选中
      // push to last section
      // 默认每题 10 分
      question.points = 10
      if (currentI !== undefined && currentI !== '' && newList[currentI] !== undefined) {
        newList[currentI].questionVOS.push(question)
      } else {
        if (isClassify && newList[0].name !== '默认大题') {
          let otherQ = {}
          let otherN = ''
          let hasPush = false
          newList.map(item => {
            if (questionType[question.questionType].label === item.name) {
              item.questionVOS.push(question)
              hasPush = true
            } else {
              otherQ = question
              otherN = questionType[question.questionType].label
            }
          })
          if (otherQ !== {} && !hasPush) {
            newList.push({ name: otherN, questionVOS: [otherQ] })
          }
        } else {
          newList[newList.length - 1].questionVOS.push(question)
        }
      }
      state.selectedQuestionList = newList
    } else {
      // 该题已存在，取消选中
      const newList = [...state.selectedQuestionList]
      newList.forEach((section, sIndex) => {
        section.questionVOS.forEach((questionitem, qIndex) => {
          if (questionitem.questionId === question.questionId) {
            newList[sIndex].questionVOS.splice(qIndex, 1)
          }
        })
      })
      state.selectedQuestionList = newList
    }
  },
  handleSelectAll(state, { questionList, isClassify, currentI }) {
    questionList = [...questionList]
    if (state.selectedQuestionList.length === 0) {
      // Section 列表为空，创建一个默认的大题
      state.selectedQuestionList = [{ name: '默认大题', questionVOS: [] }]
    }
    // if (_flattenSection(state.selectedQuestionList).length > 0) {
    //   // 如果全选前不是未选状态，清除所有选择的题目
    //   const newSelectedQuestionList = [...state.selectedQuestionList]
    //   newSelectedQuestionList.forEach(section => (section.questions = []))
    //   state.selectedQuestionList = newSelectedQuestionList
    //   return
    // }
    // 当前已选题的纯列表，去除 section 结构
    const pureQuestionList = _flattenSection(state.selectedQuestionList)
    pureQuestionList.forEach(question => {
      const index = questionList.findIndex(item => item.id === question.id)
      if (index !== -1) {
        // 删除重复题目
        questionList.splice(index, 1)
      }
    })
    const newList = [...state.selectedQuestionList]
    questionList.forEach((_, index) => {
      // 设置每题的分数
      questionList[index].points = 10
    })
    if (currentI !== undefined && currentI !== '' && newList[currentI] !== undefined) {
      newList[currentI].questionVOS.push(...questionList)
    } else {
      if (isClassify && newList[0].name !== '默认大题') {
        let otherQ = []
        let otherN = []
        let listName = []
        newList.map(n => {
          return listName.push(n.name)
        })
        questionList.map(q => {
          if (!listName.includes(questionType[q.questionType].label)) {
            listName.push(questionType[q.questionType].label)
            newList.push({
              name: questionType[q.questionType].label,
              questionVOS: []
            })
          }
        })
        newList.map(item => {
          questionList.map(q => {
            if (questionType[q.questionType].label === item.name) {
              item.questionVOS.push(q)
            }
          })
        })
        if (otherQ.length !== 0) {
          otherN = Array.from(new Set(otherN))
          let compare = (x, y) => {
            if (x < y) {
              return -1
            } else if (x > y) {
              return 1
            } else {
              return 0
            }
          }
          otherN = otherN.sort(compare)
          otherN.map(item => {
            newList.push({ name: questionType[item].label, questionVOS: [] })
          })
          newList.map(item => {
            otherQ.map(q => {
              if (questionType[q.questionType].label === item.name) {
                item.questionVOS.push(q)
              }
            })
          })
        }
      } else {
        newList[newList.length - 1].questionVOS.push(...questionList)
      }
    }
    state.selectedQuestionList = newList
  },
  handleUnselectAll(state, questionList) {
    // questionList = [...questionList]
    const newList = [...state.selectedQuestionList]
    const pureQuestionList = _flattenSection(state.selectedQuestionList)
    questionList.forEach(selectedQuestion => {
      const existQuestion = pureQuestionList.find(question => question.id === selectedQuestion.id)
      if (existQuestion) {
        newList.forEach((section, sIndex) => {
          section.questionVOS.forEach((sectionQuestion, qIndex) => {
            if (sectionQuestion.id === existQuestion.id) {
              newList[sIndex].questionVOS.splice(qIndex, 1)
            }
          })
        })
      }
    })
    state.selectedQuestionList = newList
  },
  /**
   * 更新选中的题目列表
   * @param state
   * @param newVal
   */
  setSelectedQuestionList(state, newVal) {
    state.selectedQuestionList = null
    state.selectedQuestionList = newVal
  },
  /**
   * 删除大题（包括其中小题）
   * @param state
   * @param index
   */
  deleteSection(state, index) {
    const newList = state.selectedQuestionList
    newList.splice(index, 1)
    state.selectedQuestionList = newList
  },
  /**
   * 智能出卷生成的问题结构填充到当前试卷
   * @param state
   * @param selectedQuestion
   */
  setIntelligentlyGeneratePaper(state, selectedQuestion) {
    // process data shape
    selectedQuestion = selectedQuestion.map(section => {
      section.questions = section.questionVOS
      delete section.questionVOS
      return section
    })
    state.selectedQuestionList = selectedQuestion
  },

  /**
   * 编辑试卷设置试卷详情
   * @param state
   * @param paperDetail
   */
  setEditPaper(state, paperDetail) {
    const sections = paperDetail.sectionVOS.map(section => {
      section.questions = section.questionVOS
      delete section.questionVOS
      return section
    })
    state.selectedQuestionList = sections
    state.paperTitle = paperDetail.name
  }
}

const _flattenSection = sections => {
  if (!sections || sections.length === 0) return []
  if (sections.length === 1) return sections[0].questionVOS
  const ret = sections
    .filter(section => {
      return Array.isArray(section.questionVOS)
    })
    .map(section => section.questionVOS || [])
    .reduce((preSection, curSection) => preSection.concat(curSection))
  return ret
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
