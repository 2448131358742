export default {
  beautifyTime(timestamp) {
    let mistiming = Math.round(new Date() / 1000) - timestamp / 1000
    let postfix = mistiming > 0 ? '前' : '后'
    mistiming = Math.abs(mistiming)
    let arrr = ['年', '个月', '星期', '天', '小时', '分钟', '秒']
    let arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1]

    for (let i = 0; i < 7; i++) {
      let inm = Math.floor(mistiming / arrn[i])
      if (inm !== 0) {
        return inm + arrr[i] + postfix
      }
    }
  }
}
