import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

const baseUrl = config.baseUrl + '/user/api/v1/role'

export default {
  getPermission() {
    return axios.get(baseUrl + '/permission/get')
  },
  getAllRoles() {
    return axios.get(baseUrl + '/permission/role/get')
  },
  addRole(role) {
    return axios.post(baseUrl + '/permission/role/add', role)
  },
  modifyRole(role) {
    return axios.post(baseUrl + '/modify', role)
  },
  deleteRole(roleId) {
    return axios.post(baseUrl + `/permission/role/delete?roleIds=${roleId}`)
  }
}
