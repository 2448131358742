<template>
  <!--
  /**
   * 详情咨询设计同学
   */
  -->
  <div style="display: flex;align-items: center">
    <img class="hoverImg" :src="Url" alt="" style="width: 17px;height: 17px;cursor: pointer;margin-right: 10px" @click.stop="handleClick" />
  </div>
</template>

<script>
export default {
  name: 'JYImg',
  props: {
    Url: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less" scoped></style>
